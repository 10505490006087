import React from 'react'
import { Seo } from '~/components/molecules/Seo'
import { Screen } from '~/components/screens/404'
import { site } from '~/config'

const Page = () => (
  <>
    <Seo title={`Page Not Found | ${site.title}`} />
    <Screen />
  </>
)

export default Page
