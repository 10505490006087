import React from 'react'
import { ErrorScreen } from '~/components/molecules/ErrorScreen'

export const Screen = () => (
  <ErrorScreen
    errorCode={'404'}
    errorMessage={
      'It looks like the requested page couldn’t be found. Would you like to visit our homepage?'
    }
  />
)
