import tw from 'twin.macro'
import styled from 'styled-components'

export const MicroHeading = styled.h3`
  ${tw`[font-family:'Sarabun'] uppercase tracking-widest text-xs`}
`

export const MainHeading = styled.h1`
  ${tw`text-4xl font-semibold pt-3 pb-8`}
`

export const GiddyUpButtonWrapper = styled.div`
  ${tw`pt-12`}

  > a {
    ${tw`no-underline`}
  }
`
