import React from 'react'
import * as S from './styled'
import Button from '@package/ds/button'
import Link from 'next/link'
import { ImageProps } from 'next/legacy/image'
import NextHorsePage from '../../next-horse-page'

interface Props {
  errorCode?: string
  errorMessage: string
  linkTo?: string
  NextOptimizedImage: React.ComponentType<ImageProps>
}

export const NextErrorPage = ({
  errorCode,
  errorMessage,
  linkTo = '/',
  NextOptimizedImage,
}: Props) => {
  const header = errorCode ? `${errorCode} Error` : 'Error'
  return (
    <NextHorsePage NextOptimizedImage={NextOptimizedImage}>
      <S.MicroHeading>{header}</S.MicroHeading>
      <S.MainHeading>Someone let the horse out of the barn.</S.MainHeading>
      <p>{errorMessage}</p>
      <S.GiddyUpButtonWrapper>
        <Link href={linkTo}>
          <Button>Giddy Up</Button>
        </Link>
      </S.GiddyUpButtonWrapper>
    </NextHorsePage>
  )
}
