import React from 'react'
import { Shell } from '~/components/molecules/Shell'
import NextErrorPage from '@package/ds/next-error-page'
import { OptimizedImage } from '~/components/molecules/OptimizedImage'

interface Props {
  errorCode: string
  errorMessage: string
}

export const ErrorScreen = ({ errorCode, errorMessage }: Props) => (
  <Shell>
    <NextErrorPage
      errorCode={errorCode}
      errorMessage={errorMessage}
      NextOptimizedImage={OptimizedImage}
    />
  </Shell>
)
