import React from 'react'
import * as S from './styled'
import { ImageProps } from 'next/legacy/image'

interface Props {
  NextOptimizedImage: React.ComponentType<ImageProps>
  children: React.ReactNode
}

export const NextHorsePage = ({ NextOptimizedImage, children }: Props) => (
  <S.ScreenWrapper>
    <S.HorseImageWrapper>
      <NextOptimizedImage
        priority
        alt={'purple vector horse'}
        src={'/images/horse.webp'}
        layout={'responsive'}
        width={722}
        height={654}
      />
    </S.HorseImageWrapper>
    <S.ContentWrapper>{children}</S.ContentWrapper>
  </S.ScreenWrapper>
)
