import tw from 'twin.macro'
import styled from 'styled-components'

export const ScreenWrapper = styled.div`
  ${tw`grid md:grid-cols-2 grid-rows-2 md:grid-rows-1 gap-12 lg:gap-16 py-12 md:py-28 text-white`}
`

export const HorseImageWrapper = styled.div`
  ${tw`relative [grid-row:2_/_span_1] md:[grid-row:1_/_span_1] pr-6 md:pr-0`}
`

export const ContentWrapper = styled.div`
  ${tw`flex flex-col dark:text-white md:max-w-md xl:max-w-xl pl-6 md:pl-0 pr-6`}
`
